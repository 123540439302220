// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700");

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");


@import "reset.scss";
@import "main.scss";
@import "onboarding.scss";
@import "dashboard.scss";
@import "login.scss";
