body {
    width: 100%;
    // min-height: 100vh;
    font-family: 'Inter', sans-serif;
    color: $main-dark;
    display: block;
    overflow: hidden;
}

.container-main {
    width: 100%;
    // min-height: 100vh;
}

.main-vue-container{
    max-height: 100vh;
}

.container-fluid{
    overflow: auto;
}

.wrap-content{
    min-height: 100vh;
}
.sidebar {
    width: $sidebarWidth;
    background-color: $main-dark;
    padding: 20px;
    z-index: 1;


    &__logo-wrap {
        //
    }

    &__nav {
        padding-top: 40px;

        &-list {
            display: flex;
            flex-direction: column;
            gap: 32px;
        }

        &-item {
            display: flex;
            gap: 10px;
            align-items: center;

            &--active {
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    width: 3px;
                    height: 24px;
                    position: absolute;
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: $main-light;
                }
            }
        }

        &-text {
            color: $main-light;
            font-size: 0.75rem;
        }
    }
}

.header {
    height: 72px;
    background-color: $main-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    position: sticky;
    top: 0;
    z-index: 99;

    &__title {
        font-size: 1.5rem;
        color: $main-light;
    }

    &__inner-wrap {
        display: flex;
        gap: 54px;
    }

    &__notifications {
        position: relative;

        &-count {
            position: absolute;
            top: -4px;
            right: -4px;
            width: 20px;
            height: 20px;
            font-size: 0.75rem;
            background-color: $main-light;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__user{
        &-info{
            display: flex;
            align-items: center;
            gap: 6px;
        }

        &-name {
            color: $main-light;
            font-weight: 400;
        }
        &-avatar{
            border-radius: 50px;
            max-width: 50px;
        }
    }
}

.main {
    min-height: calc(100vh - 72px);
    background-color: $main-grey;

    &--flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--layout {
        padding: 24px 30px;
    }

    &--full {
      min-height: 100vh;
    }
}

.input-wrap {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
    }

    &--search {
      &::after {
        background-image: url("/img/svg/magnifying-glass.svg");
      }
    }

    &--budget {
      &::after {
        background-image: url("/img/svg/dollar.svg");
      }
    }

    &--calendar {
      &::after {
        background-image: url("/img/svg/calendar.svg");
      }
    }


    &--margin {
      margin-bottom: 20px;
    }

    &--wide {
      width: 100%;
    }
  }

  .text-input {
    border: 1px solid $border-dark;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 8px 16px 8px 40px;

    &::placeholder {
      font-size: 0.875rem;
      font-weight: 400;
      color: $grey-05;
    }

    &--search {
      width: 396px;
      height: 42px;

      &-wide {
        width: 100%;
      }
    }

    &--budget {
      width: 100%;
      height: 32px;
    }

    &--calendar {
      width: 312px;
      height: 42px;
    }

    &--login {
      width: 100%;
      height: 46px;
    }
  }

  .button {
    background-color: $main-dark;
    color: $main-light;
    border-radius: 4px;
    padding: 10px;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &--transparent {
      background-color: transparent;
      border: 1px solid $main-dark;
      color: $main-dark;
    }

    &--middle {
        width: 110px;
    }

    &--large {
        width: 170px;
    }

    &--full {
      width: 100%;
    }
  }

  .icon-wrap {
    width: 20px;
    height: 20px;
    background-color: $grey-08;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &--transparent {
      background-color: transparent;
    }
  }

  select {
    font-size: 0.875rem;
        font-family: 'Inter', sans-serif;
  }

.error-message-bag{
    top: 80px;
    right: 0;
    max-width: 30%;
    z-index: 99999;

    .close{
        position: absolute;
        top: 0;
        right: 0;
    }
}

a:hover{
    color: white;
}

.checkbox-img{
    min-width: 30px;
}

.vue-tabs{
    padding: 20px;
}
