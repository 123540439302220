.dashboard {
    &__top-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &__filters-wrap {
        display: flex;
        gap: 24px;
        position: relative;
    }

    &__filters-dropdown {
        position: absolute;
        top: 145px;
        right: 24%;
        width: 376px;
        padding: 32px;
        background-color: $main-light;
        box-shadow: 0px 4px 20px 0px #C8C8C840;

        @media screen and (max-width: 568px) {
            right: unset;
            width: unset;

        }
    }

    &__dropdown{
        &-title {
            font-weight: 600;
            font-size: 1.125rem;
            margin-bottom: 16px;
        }

        &-subtitle {
            font-size: 0.875rem;
            font-weight: 600;
            margin-bottom: 8px;
        }

        &-buttons-wrap {
            display: flex;
            justify-content: space-between;

            & button {
                width: 142px;
            }
        }

        &--active {
            & img {
                transform: rotateZ(180deg);
            }
        }
    }


    &__select-wrap {
        padding-right: 16px;
        border: 1px solid $border-grey;
        border-radius: 4px;
        position: relative;

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }

        &:last-of-type {
            margin-bottom: 24px;
        }

        &::after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          background-image: url("/img/svg/arrow-down-dark.svg");
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
        }
      }

      &__select {
        padding: 12px 16px;
        font-weight: 400;
        background-color: transparent;
        width: 100%;
        height: 42px;
        border: none;
        appearance: none;

        &:focus {
          outline: none;
          border: none;
        }
      }

      &__radio-wrap {
          display: flex;
          margin-bottom: 24px;
          width: 100%;
          position: relative;
      }

      &__radio {
          width: 1px;
          height: 1px;
          position: absolute;
          opacity: 0;

          &-label {
              width: 50%;
              height: 36px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              background-color: $grey-01;
              color: $grey-08;
              cursor: pointer;

              &:first-of-type {
                  border-bottom-right-radius: 0px;
                  border-top-right-radius: 0px;
              }

              &:last-of-type {
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
            }
          }
      }

      &__radio:checked + label {
        background-color: $main-dark;
        color: $main-light;
      }

    &__table {
        font-size: 0.75rem;
        margin-bottom: 16px;
        padding-bottom: 115px;

        &-head {
            display: grid;
            grid-template-columns: 2.25fr 50px repeat($grid-template-columns, 1fr) 30px;

        }
    }

    &__head {
        &-item {
            color: $grey-04;
            align-items: center;
            height: 56px;
            margin: 0;
            padding: 0;
        }
    }

    &__body {

        &-item-wrap {
            border-radius: 10px;
            overflow: hidden;

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }

        &-item {
           color: $grey-09;
           background-color: $main-light;
           display: grid;
            grid-template-columns: 2.25fr 50px repeat($grid-template-columns, 1fr) 30px;
            align-items: center;
            height: 56px;
            text-align: center;
            font-size: 13px;

            &--expanded {
                background-color: $background-accent;
            }
        }

        &-subitem {
            grid-template-columns: 0.3fr 2.25fr 50px repeat($grid-template-columns, 1fr) 30px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                height: 1px;
                width: calc(100% - 24px);
                top: 0px;
                right: 4px;
                display: block;
                background-color: $border-dark;
            }
        }

        &-second-subitem {
            grid-template-columns: 0.25fr 0.25fr 0.5fr 1.75fr repeat($grid-template-columns + 1, 1fr) 0.5fr;
        }
    }

    &__checkbox-wrap {
        position: relative;
    }

    &__checkbox {
        width: 1px;
        height: 1px;
        opacity: 0;
        position: absolute;

        &-label {
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid $grey-10;
            border-radius: 2px;
            margin: 0 auto;
            cursor: pointer;
        }

        &:checked + label {
            background-color: $main-dark;
            background-image: url("/img/svg/success.svg");
            background-position: center;
            background-size: 40% 30%;
            background-repeat: no-repeat;
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &-wrap {
            background-color: $main-light;
            height: 56px;
            padding: 10px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.75rem;
            position: sticky;
            bottom: 0;
            z-index: 9;
        }

        &-info {
            font-weight: 600;
        }

        &-button {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $grey-10;
            border-radius: 4px;
            font-size: 0.625rem;
            cursor: pointer;

            &--active {
                background-color: $grey-09;
                color: $main-light;
            }
        }

        &-arrow {
            background-position: center;
            background-repeat: no-repeat;

            &--right {
                background-image: url("/img/svg/arrow-right-pagination.svg");
                transform: rotateZ(0deg);

                &:disabled {
                    background-image: url("/img/svg/arrow-left-pagination-disabled.svg");
                    transform: rotateZ(1800deg);
                }
            }

            &--left {
                background-image: url("/img/svg/arrow-right-pagination.svg");
                transform: rotateZ(180deg);

                &:disabled {
                    background-image: url("/img/svg/arrow-left-pagination-disabled.svg");
                    transform: rotateZ(0deg);
                }
            }
        }
    }

    &__per-page {
        &-wrap {
            display: flex;
            align-items: center;
            font-weight: 600;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background-image: url("/img/svg/arrow-down-dark.svg");
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
              }
        }

        &-select {
            font-size: 0.875rem;
            font-weight: 600;
            color: $main-dark;
            appearance: none;
            cursor: pointer;
            border: none;
            width: 48px;
            padding: 0px 12px;

            &:focus {
                outline: none;
                border: none;
            }
        }
    }

    &__filter-bar {
        background-color: $main-dark;
        width: 1103px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        padding: 0 30px;
        z-index: 2;

        &-button {
            background-color: transparent;
            color: $main-light;
            border: none;
            outline: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 6px;
        }

        &-choosen {
            font-size: 0.75rem;
            color: $grey-04;
        }

        &-inner {
            display: flex;
            gap: 40px;
        }
    }
}


.dashboard__body-item{
    color: black;
}

.account_red-danger{
    background: #FFFFFF;
    border: 1.5px solid #F05757;
    border-radius: 7px;
}
.red-danger{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #F05757;
}

.red-danger_cpr-table {
    background: #F05757;
    border-radius: 5px;
    width: 73px;
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -17px;
}

.account_orange-warning{
    background: #FFFFFF;
    border: 1.5px solid #FF8C00;
    border-radius: 7px;
}
.orange-warning{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #FF8C00;
}

.orange-warning_cpr-table {
    background: #FF8C00;
    border-radius: 5px;
    width: 73px;
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -17px;
}

/********************************/
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #34A853;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  @media only screen and (max-width: 1280px) {
    .sidebar {
        display: none;
    }
    .dashboard__table{
        min-width: 1000px;
    }
  }

  @media screen and (max-width: 500px) {
    .header{
        position: fixed;
        width: 100%;
    }

  }
