.login {
    &__wrap {
        flex-basis: 50%;
        height: 100vh;
        padding: 36px;

        &--light {
            background-color: $main-light;
        }
    }

    &__container {
        width: 100%;
        max-width: 375px;
        margin: 0 auto;
    }

    &__inner-wrap {
        display: flex;
        justify-items: center;
        align-items: center;
    }

    &__content {
        width: 100%;
    }

    &__title {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 36px;
    }

    &__field-title {
        font-size: 0.875rem;
        margin-bottom: 8px;
    }

    &__password-button {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        background: transparent;
        border: none;
        outline: none;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &__flex-end {
        display: flex;
        justify-content: flex-end;
        padding-top: 12px;
    }

    &__link {
        font-size: 0.875rem;
        color: $main-dark;

        &--margin {
            margin-bottom: 36px;
        }
    }

    &__submit-button {
        font-weight: 500;
        margin-bottom: 16px;
    }

    &__info-text {
        color: $grey-05;
        font-size: 0.875rem;
        text-align: center;
        margin-bottom: 20px;
    }

    &__socials{

        &-title {
        display: flex;
        justify-content: center;
        position: relative;
        font-size: 0.875rem;
        margin-bottom: 24px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 45%;
            height: 1px;
            background-color: $border-grey;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 45%;
            height: 1px;
            background-color: $border-grey;
        }
    }

        &-wrap {
            display: flex;
            justify-content: space-between;
            gap: 12px;
        }

        &-button {
            border: 1px solid $border-grey;
            height: 46px;
            flex-grow: 1;
        }

}
}

.btn-square{
    position: relative;
}

.logo_auth{
    width: 100%;
}

.help-block-error{
    color: red;
    font-size: 12px;
}

.status_login{
    position: absolute;
    width: 15px;
    top: 1px;
    right: 5px;
}

@media only screen and (max-width: 600px) {
    .login {
        &__wrap {
            flex-basis: 100%;
            overflow-x: auto;
        }
        &__inner-wrap {
            height: calc(100vh - 200px);
            display: block;
        }
    }
  }
