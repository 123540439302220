.onboarding {
  background-color: $main-light;
  border-radius: 16px;
  padding: 50px 100px;
  min-height: 550px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

  &__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 72px;
    margin-bottom: 40px;
  }

  &__count {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-01;
    color: $grey-03;
    font-size: 0.75rem;
    font-weight: 400;
    position: relative;

    &:not(:last-of-type) {
      &::after {
        content: "";
        display: block;
        width: 40px;
        height: 2px;
        background: url("/img/svg/dashes.svg");
        position: absolute;
        top: 50%;
        right: -56px;
        transform: translateY(-50%);
      }
    }

    &--active {
      background-color: $main-dark;
      color: $main-light;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
  }

  &__subtitle {
    font-size: 0.875rem;
    font-weight: 400;
    color: $grey-04;
    text-align: center;
    margin-bottom: 16px;
  }

  &__section-title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__info {
    padding-bottom: 16px;
    max-width: 586px;
    min-width: 586px;
  }

  &__input-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 18px;

    p {
      width: max-content;
    }
  }

  &__button-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  &__success-img {
    display: block;
    margin: 0 auto 36px;
  }

  &__filter-wrap {
    display: block;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
  }

  &__select-wrap {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-image: url("/img/svg/arrow-down-dark.svg");
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__select {
    font-size: 0.875rem;
    font-weight: 400;
    color: $main-dark;
    appearance: none;
    cursor: pointer;
  }

  &__condition-select-wrap {
    &::after {
      right: 48px;
    }
  }

  &__condition-select {
    width: 160px;
    height: 40px;
    padding: 8px 16px;
    text-align: center;
    border: 1px solid $border-grey;
  }

  &__table {
    width: 100%;
    border: 1px solid $border-grey;
    border-radius: 4px;
    font-size: 0.75rem;

    &-head {
      height: 36px;
      font-weight: 600;
      border-bottom: 1px solid $border-grey;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding-left: 20px;
      padding-right: 6px;

      & > div {
        display: flex;
        align-items: center;
        position: relative;
      }
    }

    &-body {
      height: 278px;
      max-height: calc(70vh - 380px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $main-dark;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding-left: 20px;
      padding-right: 6px;

      & div {
        height: 44px;
        display: flex;
        align-items: center;
      }
    }
  }

  &__condition-img {
    margin-right: 8px;
  }

  &__accounts {
    &-list {
      margin-bottom: 16px;
      max-height: calc(70vh - 400px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $main-dark;
      }
    }

    &-item {
      height: 48px;
      width: 100%;
      border: 1px solid $border-grey;
      border-radius: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: $grey-07;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &-name {
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      gap: 6px;
      flex-grow: 1;
      padding: 12px 16px;
    }

    &-circle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $grey-05;
    }

    &-select-wrap {
      padding-right: 16px;

      &::after {
        right: 18px;
      }

      &::before {
        content: "";
        width: 1px;
        height: 24px;
        background-color: $border-grey;
        position: absolute;
        top: 50%;
        left: -4px;
        transform: translateY(-50%);
      }
    }

    &-select {
      padding: 12px 16px;
      border: none;
      font-weight: 400;
      background-color: transparent;

      &:focus {
        outline: none;
        border: none;
      }
    }

    &-label {
      flex-grow: 1;
      cursor: pointer;
      position: relative;

      &::before {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          background-color: $main-dark;
          background-image: url("/img/svg/success.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 40% 35%;
          padding: 4px;
          border-radius: 50%;
          top: 50%;
          left: -28px;
          transform: translateY(-50%);
          opacity: 0;
      }
    }

    &-checkbox {
      opacity: 0;
      width: 1px;
      height: 1px;
      position: absolute;

      &:checked + label {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  &__name-account{
    margin-left: 40px;
  }

  &__sorting {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      cursor: pointer;

      &::before {
        content: "";
        display: block;
        width: 6px;
        height: 4px;
        background-image: url("/img/svg/arrow-up-filled.svg");
        background-position: center;
        position: absolute;
        top: 1px;
        right: -10px;
      }

      &::after {
        content: "";
        display: block;
        width: 6px;
        height: 4px;
        background-image: url("/img/svg/row-down-filled.svg");
        background-position: center;
        position: absolute;
        bottom: 1px;
        right: -10px;
      }
  }
}


@media (max-width: 768px) {
    .main-vue-container{
        padding-top: 85px;
    }

    .vue-tabs{
        padding: 5px;

        .tab-content{
            margin-top: 10px;
        }
    }


    .onboarding {
        padding: 0;
        padding-top: 10px;

        &__counter{
            gap: 10px;
        }

        &__content{
            width: 100%;
        }

        &__info{
            max-width: 100%;
            min-width: 100%;
        }

        .input-wrap--wide{
            width: auto;
        }

        .account-item{
            margin-right: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
