// Body
$body-bg: #F1F3F4;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$main-dark: #242424;
$main-light: #fff;
$main-grey: #E5E5E5;

$grey-01: #E3E3E3;
$grey-02: #C5C5C5;
$grey-03: #AAAAAA;
$grey-04: #979797;
$grey-05: #B6B6B6;
$grey-07: #F5F5F5;
$grey-08: #717171;
$grey-09: #1D1D1D;
$grey-10: #DCDCDC;

$border-grey: #EBEBEB;
$border-dark: #E3E7ED;

$background-accent: #F8FAFF;

$sidebarWidth: 210px;
$grid-template-columns: 15;

